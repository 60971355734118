import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table, Input, DatePicker } from "antd";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import TableActionDropDown from "./TableActionDropDown";
import { SearchOutlined } from "@ant-design/icons";
import { lowerCase } from "lodash";
import moment from "moment";

function DynamicTable({
  style = {},
  scroll,
  columns = [],
  defaultColumns = {},
  editHandler = () => {},
  onRowDelete = () => {},
  requireEdit = true,
  deleteBtn = false,
  dataSource = [],
  showAction,
  pagination = false,
  renderActions,
  showActionColumn = true,
  onCurrentPageData = () => {},
  ...props
}) {
  const [showColumns, setShowColumns] = useState(defaultColumns);
  const [searchText, setSearchText] = useState(null);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm({
      closeDropdown: true,
    });
    setSearchText({ dataIndex, value: selectedKeys[0] });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
  };

  const getFilter = ({ dataIndex, filterType }) => {
    switch (filterType) {
      case "search":
        return getColumnSearchProps(dataIndex);
      case "date": {
        return getColumnSearchPropsForDateTime(dataIndex);
      }
      case "filter": {
        return {
          filterSearch: true,
          onFilter: (value, record) => {
            let indexValue = dataIndex.split(".").reduce((indexValue, key) => {
              indexValue = indexValue?.[key];
              return indexValue;
            }, record);
            return indexValue == value;
          },
        };
      }
      default:
        return {};
    }
  };

  const getColumns = (columns, showColumns) => {
    let finalColumns = columns.reduce((arrayColumns, column) => {
      let splitedDataIndex = column.dataIndex.split(".");
      if (
        !showColumns[splitedDataIndex[0]] ||
        showColumns[splitedDataIndex[0]].show
      ) {
        let filters = getFilter(column);
        arrayColumns.push({
          render: (_, record) => {
            return splitedDataIndex.reduce((indexValue, key) => {
              indexValue = indexValue?.[key];
              return indexValue;
            }, record);
          },
          ...filters,
          ...column,
        });
      }
      return arrayColumns;
    }, []);
    return finalColumns;
  };

  const getColumnSearchPropsForDateTime = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8, width: 240 }}>
        <DatePicker.RangePicker
          onChange={(e, dates) => {
            if (!e) {
              setSelectedKeys(e);
              return;
            }
            let dateFrom = dates[0].split(" ");
            dateFrom = dateFrom[0] + " 00:00:00";
            let dateTo = dates[1].split(" ");
            dateTo = dateTo[0] + " 23:59:59";
            setSelectedKeys([[dateFrom, dateTo]]);
          }}
          value={
            selectedKeys?.[0]
              ? [
                  selectedKeys[0][0] ? moment(selectedKeys[0][0]) : null,
                  selectedKeys[0][1] ? moment(selectedKeys[0][1]) : null,
                ]
              : selectedKeys
          }
          disabledDate={(current) =>
            current.isAfter(moment().subtract(0, "day"))
          }
          format="YYYY-MM-DD hh:mm:ss"
        />
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              close();
            }}
            style={{
              width: 90,
            }}
          >
            Ok
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: false });
              close();
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        moment(record[dataIndex]).isBetween(value[0], value[1])
      );
    },
    render: (text) => moment(text).format("DD MMM YYYY"),
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Type here ...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: true,
              });
              setSearchText({ dataIndex, value: selectedKeys[0] });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      let indexValue = dataIndex.split(".").reduce((indexValue, key) => {
        indexValue = indexValue?.[key];
        return indexValue;
      }, record);
      return indexValue?.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchText?.dataIndex == dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText?.value]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        <div>
          {dataIndex.split(".").reduce((indexValue, key) => {
            indexValue = indexValue?.[key];
            return indexValue;
          }, record)}
        </div>
      ),
  });

  const tableColumns = [...columns];
  if (showActionColumn) {
    tableColumns.push({
      title: Object.keys(defaultColumns).length ? (
        <TableActionDropDown
          showColumns={showColumns}
          setShowColumns={setShowColumns}
        />
      ) : (
        ""
      ),
      key: "operation",
      dataIndex: "action",
      // fixed: "right",
      width: 90,
      render: (_, record, idx) => {
        if (renderActions) {
          return renderActions(_, record, idx);
        }
        if (showAction && !showAction(record)) {
          return;
        }
        return (
          <Space>
            {requireEdit ? (
              <Button type="link">
                <EditFilled onClick={() => editHandler(record, idx)} />
              </Button>
            ) : null}
            {deleteBtn && (
              <Popconfirm
                placement="topLeft"
                title="Are you sure, to delete this row?"
                okText="Yes"
                onConfirm={() => onRowDelete(record)}
              >
                <DeleteOutlined style={styles.actionBtn} />
              </Popconfirm>
            )}
          </Space>
        );
      },
    });
  }

  return (
    <Table
      size="small"
      dataSource={dataSource}
      rowKey="id"
      cellFontSize={11}
      columns={getColumns(tableColumns, showColumns)}
      pagination={pagination}
      scroll={scroll}
      style={style}
      {...props}
    />
  );
}

export default DynamicTable;
const styles = {
  actionBtn: { fontSize: 18, color: "#339af0", padding: "5px" },
};
